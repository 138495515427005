<template>
  <div v-if="authenticated && !$apollo.loading" class="position-relative">
    <b-container v-if="$route.meta.fromAccount" class="p-2 bg-white">
      <router-link
        :to="{ name: 'account' }"
        class="btn btn-outline-primary btn-sm"
      >
        <font-awesome-icon icon="arrow-left" fixed-width />
      </router-link>
    </b-container>
    <venues
      v-if="matchedVenues"
      :venues="matchedVenues"
      :display-menu="false"
      :advertiser-day="advertiserDay"
      view="bookmarks"
    />
    <portal to="header-top">
      <account-link />
      <h4
        class="flex-fill text-center mb-0 text-white text-uppercase font-weight-demi"
      >
        Mes favoris
      </h4>
      <b-nav-form class="ml-auto" @submit.prevent="searching = !searching">
        <b-button size="sm" class="my-2 my-sm-0 flat" type="submit">
          <font-awesome-icon
            v-if="!searching"
            icon="search"
            fixed-width
            size="lg"
          />
          <font-awesome-icon
            v-else
            icon="times"
            fixed-width
            size="lg"
            @click="search = ''"
          />
        </b-button>
      </b-nav-form>
    </portal>
    <portal to="header-bottom">
      <b-form v-show="searching" class="w-100 px-3" @submit.prevent>
        <b-form-group class="mb-0">
          <b-form-input
            v-model="search"
            class="my-2 rounded-pill"
            placeholder="Recherche dans mes favoris"
            size="sm"
          />
        </b-form-group>
      </b-form>
    </portal>
    <portal to="content">
      <transition name="swipe">
        <router-view />
      </transition>
    </portal>
  </div>
  <div
    v-else-if="$apollo.loading"
    class="h-100 d-flex align-items-center justify-content-center bg-page-dark"
  >
    <font-awesome-icon
      :icon="['fas', 'spinner']"
      spin
      size="2x"
      class="text-white"
    />
  </div>
  <div v-else class="h-100 d-flex align-items-center justify-content-center">
    <b-btn variant="primary" @click="login">
      Connectez-vous pour voir vos favoris
    </b-btn>
  </div>
</template>
<script>
import VENUES from "@/graphql/Venues.gql"
import venueList from "@/mixins/VenueList"
import ADVERTISER_DAY from "@/graphql/AdvertiserDay.gql"
export default {
  mixins: [venueList],
  data() {
    return {
      searching: false,
      search: ""
    }
  },
  apollo: {
    venues: {
      query: VENUES,
      skip: true,
      variables: {
        onlyBookmarked: true,
        radius: 0,
        doesntHaveTags: []
      },
      error() {
        this.$store.commit("loginModule/authenticated", false)
      }
    },
    advertiserDay: {
      query: ADVERTISER_DAY
    }
  },
  computed: {
    matchedVenues: function () {
      if (!this.$apollo.loading && this.venues) {
        if (this.search === "") {
          return this.venues.data
        } else {
          var regExp = new RegExp(this.search, "ig")
          return this.venues.data.filter(v => regExp.test(v.name))
        }
      } else {
        return []
      }
    }
  },
  watch: {
    authenticated: function (newValue, oldValue) {
      if (this.authenticated && newValue != oldValue) {
        this.fetch()
      }
    }
  },
  mounted() {
    if (!this.authenticated) {
      this.$login.login().then(() => {
        this.fetch()
      })
    } else {
      this.fetch()
    }
  },
  methods: {
    login() {
      this.$login.login()
    },
    fetch() {
      this.$apollo.queries.venues.skip = false
      this.$apollo.queries.venues.refetch()
    }
  }
}
</script>
